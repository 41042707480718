module.exports = [{
      plugin: require('C:/Users/david.beeler/Documents/Development/its-jv/node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography"},
    },{
      plugin: require('C:/Users/david.beeler/Documents/Development/its-jv/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":2048},
    },{
      plugin: require('C:/Users/david.beeler/Documents/Development/its-jv/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-143847387-1","head":true},
    },{
      plugin: require('C:/Users/david.beeler/Documents/Development/its-jv/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
