// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-news-index-js": () => import("C:\\Users\\david.beeler\\Documents\\Development\\its-jv\\src\\templates\\news-index.js" /* webpackChunkName: "component---src-templates-news-index-js" */),
  "component---src-templates-index-page-js": () => import("C:\\Users\\david.beeler\\Documents\\Development\\its-jv\\src\\templates\\index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-contact-page-js": () => import("C:\\Users\\david.beeler\\Documents\\Development\\its-jv\\src\\templates\\contact-page.js" /* webpackChunkName: "component---src-templates-contact-page-js" */),
  "component---src-templates-news-post-js": () => import("C:\\Users\\david.beeler\\Documents\\Development\\its-jv\\src\\templates\\news-post.js" /* webpackChunkName: "component---src-templates-news-post-js" */),
  "component---src-templates-tags-js": () => import("C:\\Users\\david.beeler\\Documents\\Development\\its-jv\\src\\templates\\tags.js" /* webpackChunkName: "component---src-templates-tags-js" */),
  "component---src-pages-404-js": () => import("C:\\Users\\david.beeler\\Documents\\Development\\its-jv\\src\\pages\\404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

